import React from 'react';
import Sidebar from './sidebar';
import sidelogo from '../style/assets/images/edudronimg.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';  // Import useNavigate

const Header = ({ toggleSidebar, isSidebarOpen }) => {
  const navigate = useNavigate();  // To handle redirection

  // Logout function
  const handleLogout = async () => {
    
    try {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;  // Assuming you have this in .env
      const response = await fetch(baseUrl + "logout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const result = await response.json();  // Parse the JSON response
     
      if (result.success) {
        // Clear token from localStorage
        localStorage.removeItem('token');
        // Redirect to login page
        navigate('/');
      
      } else {
        console.error("Logout failed: ", result.message);
        alert(result.message || "Logout failed. Please try again.");
      }
    } catch (error) {
      console.error("Error during logout:", error);
      alert("An error occurred during logout. Please try again.");
    }
  };

  return (
    <div>
      <div className="navbar-header">
        <div className="">
          <div className=" d-flex">
            <div className="d-flex flex-wrap align-items-center gap-4">
              <button type="button" className={`sidebar-toggle ${isSidebarOpen ? 'active' : ''}`}
                onClick={toggleSidebar}>
                <FontAwesomeIcon icon={faBars} className="my-bars-icon" style={{ color: '#fff' }} />
              </button>

              <button type="button" className="sidebar-mobile-toggle"> </button>

              <form className="navbar-search">
                <input type="text" name="search" placeholder="Search" />
              </form>
            </div>
            <div className='ms-auto'>
              <button className='btn btn-danger' onClick={handleLogout}>
                <svg className='me-2 feather feather-log-out' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                  <polyline points="16 17 21 12 16 7"></polyline>
                  <line x1="21" y1="12" x2="9" y2="12"></line>
                </svg>
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

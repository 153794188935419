// import React from 'react'
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react';


function User() {
  // const baseUrl = process.env.REACT_APP_API_BASE_URL;
  // console.log(baseUrl);

  const [data, setData] = useState([])
  useEffect(() => {
    fetch("").then((result) => {
      result.json().then((resp) => {
        //  console.log(resp);
        setData(resp)
      })
    })
  }, [])
  console.log(data)
                   
  return (
    <div className=''>
      <div className="dashboard-main-body">
        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mb-24">
          <h6 className="fw-semibold mb-0">All User's</h6>
          <ul className="d-flex align-items-center gap-2">
            <li className="fw-medium">
              <Link to="/" className="d-flex align-items-center gap-1 hover-text-primary">
                <iconify-icon icon="solar:home-smile-angle-outline" className="icon text-lg"></iconify-icon>
                Dashboard
              </Link>
            </li>
            <li>-</li>
            <li className="fw-medium">All  User's</li>
          </ul>
        </div>

        <div className="card h-100 p-0 radius-12">
          <div className="card-header border-bottom bg-base py-16 px-24 d-flex align-items-center flex-wrap gap-3 justify-content-between">
            <div className="d-flex align-items-center flex-wrap gap-3">
              <span className="text-md fw-medium text-secondary-light mb-0">Show</span>
              <select className="form-select form-select-sm w-auto ps-12 py-6 radius-12 h-40-px">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
              </select>
              <form className="navbar-search">
                <input type="text" className="bg-base h-40-px w-auto" name="search" placeholder="Search" />
                <iconify-icon icon="ion:search-outline" className="icon"></iconify-icon>
              </form>
              <select className="form-select form-select-sm w-auto ps-12 py-6 radius-12 h-40-px">
                <option>Status</option>
                <option>Active</option>
                <option>Inactive</option>
              </select>
            </div>
            <Link to="/add-collage" className="btn btn-primary text-sm btn-sm px-12 py-12 radius-8 d-flex align-items-center gap-2">
              <iconify-icon icon="ic:baseline-plus" className="icon text-xl line-height-1"></iconify-icon>
              Add Collage
            </Link>
          </div>
          <div className="card-body p-24">
            <div className="table-responsive scroll-sm">
              <table className="table bordered-table sm-table mb-0">
                {/* <thead>
                  <tr>
                    <th scope="col">
                      <div className="d-flex align-items-center gap-10">
                        <div className="form-check style-check d-flex align-items-center">
                          <input className="form-check-input radius-4 border input-form-dark" type="checkbox" name="checkbox" id="selectAll" />
                        </div>
                        S.L
                      </div>
                    </th>
                    <th scope="col">Join Date</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Department</th>
                    <th scope="col">Course</th>
                    <th scope="col" className="text-center">Status</th>
                    <th scope="col" className="text-center">Action</th>
                  </tr>
                </thead> */}

                <tbody>
                  {
                    data.map((item) => {
                      return (
                        <tr>
                          <td>
                            <div className="d-flex align-items-center gap-10">
                              <div className="form-check style-check d-flex align-items-center">
                                <input className="form-check-input radius-4 border border-neutral-400" type="checkbox" name="checkbox" id="SL" />
                              </div>
                              {item?.id}
                            </div>
                          </td>
                          <td>25 Jan 2024</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <img src="/userimg.jpeg" alt="" className="w-40-px h-40-px rounded-circle flex-shrink-0 me-12 overflow-hidden" />
                              {/* {item.image} */}
                              <div className="flex-grow-1">
                                {/* <span className="text-md mb-0 fw-normal text-secondary-light">Kathryn Murphy</span> */}
                              {item.category}
                              </div>
                            </div>
                          </td>
                          <td><span className="text-md mb-0 fw-normal text-secondary-light">osgoodwy@gmail.com</span></td>
                          <td>Law</td>
                          <td>Manager</td>
                          <td className="text-center">
                            <span className="bg-success-focus text-success-600 border border-success-main px-24 py-4 radius-4 fw-medium text-sm">Active</span>
                          </td>
                          <td className="text-center">
                            <div className="d-flex align-items-center gap-10 justify-content-center">
                              <button type="button" className="bg-info-focus bg-hover-info-200 text-info-600 fw-medium w-40-px h-40-px d-flex justify-content-center align-items-center rounded-circle">
                                <iconify-icon icon="majesticons:eye-line" className="icon text-xl"></iconify-icon>
                              </button>
                              <button type="button" className="bg-success-focus text-success-600 bg-hover-success-200 fw-medium w-40-px h-40-px d-flex justify-content-center align-items-center rounded-circle">
                                <iconify-icon icon="lucide:edit" className="menu-icon"></iconify-icon>
                              </button>
                              <button type="button" className="bg-danger-focus bg-hover-danger-200 text-danger-600 fw-medium w-40-px h-40-px d-flex justify-content-center align-items-center rounded-circle">
                                <iconify-icon icon="fluent:delete-24-regular" className="menu-icon"></iconify-icon>
                              </button>
                            </div>
                          </td>
                        </tr>
                      )

                    })
                  }
                 
                </tbody>
              </table>
            </div>

            <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 mt-24">
              <span>Showing 1 to 10 of 12 entries</span>
              <ul className="pagination d-flex flex-wrap align-items-center gap-2 justify-content-center">
                <li className="page-item">
                  <a className="page-link bg-neutral-300 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md" href="javascript:void(0)"><iconify-icon icon="ep:d-arrow-left" className=""></iconify-icon></a>
                </li>
                <li className="page-item">
                  <a className="page-link text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md bg-primary-600 text-white" href="javascript:void(0)">1</a>
                </li>
                <li className="page-item">
                  <a className="page-link bg-neutral-300 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px" href="javascript:void(0)">2</a>
                </li>
                <li className="page-item">
                  <a className="page-link bg-neutral-300 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md" href="javascript:void(0)">3</a>
                </li>
                <li className="page-item">
                  <a className="page-link bg-neutral-300 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md" href="javascript:void(0)">4</a>
                </li>
                <li className="page-item">
                  <a className="page-link bg-neutral-300 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md" href="javascript:void(0)">5</a>
                </li>
                <li className="page-item">
                  <a className="page-link bg-neutral-300 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md" href="javascript:void(0)"> <iconify-icon icon="ep:d-arrow-right" className=""></iconify-icon> </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default User

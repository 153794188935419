
import React, { Suspense, useEffect, useState } from 'react';

import { Navigate,Switch, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import '../src/style/assets/css/style.css';
import '../src/style/assets/css/lib/bootstrap.min.css';
import '../src/style/assets/css/remixicon.css';
import '../src/style/assets/css/lib/apexcharts.css';
import '../src/style/assets/css/lib/dataTables.min.css';
import '../src/style/assets/css/lib/editor-katex.min.css';
import Header from './Dashboard/header';
import User from './componets/user';
import Sidemenu from './Dashboard/sidebar';
import Deshboard from './Dashboard/dashboard';
import AddCollage from './componets/addCollage';
import AllCollage from './componets/allCollage';
import LoginForm from './auth/login';

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();  // Get the current route
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsSidebarOpen(prevState => !prevState);
  };

  const isLoginPage = location.pathname === '/';  // Check if the current route is the login page
 


 
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      navigate("/");  // Redirect to login if no token
    }
  }, [navigate, token]); 

  if (!token) {
    {console.log('token not found')}
    return (
      <Suspense >
          <Routes>
          <Route path='/' element={<LoginForm />} />
          </Routes>

      </Suspense>
    )
  } else {
    return (
    <>
    {console.log(isLoginPage)}
    {/* {!isLoginPage && <Sidemenu isSidebarOpen={isSidebarOpen} />}
      <div className={`dashboard-main ${isSidebarOpen && !isLoginPage ? 'active' : ''}`}>
        {!isLoginPage && <Header toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />} */}

      { <Sidemenu isSidebarOpen={isSidebarOpen} />}
      <div className={`dashboard-main ${isSidebarOpen  ? 'active' : ''}`}>
        { <Header toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />}
       
        <Routes>
          <Route path='/dashboard' element={<Deshboard />} />
          <Route path='/user' element={<User />} />
          <Route path='/add-collage' element={<AddCollage />} />
          <Route path='/all-collage' element={<AllCollage />} />
        </Routes>
      </div>
    </>
  );
}}

export default App;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import "../style/assets/css/LoginForm.css";

const LoginForm = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
 
  const [email, setEmail] = useState(""); // Corrected function name
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    

    const data = { email, password };

    try {
      const response = await fetch(baseUrl + "login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json(); // Parse the JSON response
       console.log(result);
      if (result.status == true  ) {
        // Handle successful login (you might want to store a token here)
        localStorage.setItem("token", result.access_token);
        console.log("Login successful", result);
        navigate("/dashboard"); 

      } else {
        // Handle login errors
        console.warn("Login failed", result.message);
        localStorage.removeItem("token")
        navigate("/"); 
        alert(result.message || "Login failed! Please try again.");
      }
    } catch (error) {
      console.error("Error during login:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <div id="login-form">
      <h1>Login</h1>
      <form onSubmit={handleSubmit}> {/* Attach submit handler */}
        <label htmlFor="email">email:</label>
        <input
          type="text"
          id="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)} // Update username
        />
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)} // Update password
        />
        <input type="submit" value="Submit" />
      </form>
    </div>
  );
};

export default LoginForm;

import React from 'react'
import { Link, } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { FaEdit, FaTrash, FaEye } from 'react-icons/fa';

import DataTable from 'react-data-table-component';

function AllCollage() {
                             
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const [filteredCollage, setFilteredCollage] = useState([]); // To hold filtered data
  const [search, setSearch] = useState(""); // For capturing the search input

  const [allcollage, setAllCollage] = useState([])
  useEffect(() => {

    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    fetch(baseUrl + "get-colleges", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllCollage(result?.data);
        console.log(result)
      })
      .catch((error) => { console.error(error) });

  }, [])

  // Filter the data when search input changes
  useEffect(() => {
    const filteredData = allcollage.filter((item) => {
      return (
        item.college_name.toLowerCase().includes(search.toLowerCase()) ||
        item.state?.state.toLowerCase().includes(search.toLowerCase()) ||
        item.email.toLowerCase().includes(search.toLowerCase()) ||
        item.phone.toLowerCase().includes(search.toLowerCase())
        
      );
    });

    setFilteredCollage(filteredData); // Update filtered data
  }, [search, allcollage]);

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this college?")) {
      
      const token = localStorage.getItem('token')
      const requestOptions = {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token // If authentication is required
        },
        redirect: "follow"
      };
  
      // Send the user ID in the params of the URL
      fetch(`${baseUrl}delete-college/${id}`, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((result) => {
          if (result.status) {
            alert('College deleted successfully!');
            // Remove the deleted college from the state
            setAllCollage(allcollage.filter(college => college._id !== id));
          } else {
            alert('Failed to delete the college.');
          }
        })
        .catch((error) => {
          console.error('Error deleting college:', error);
          alert('An error occurred while trying to delete the college.');
        });
    }
  };
  

  // const handleEdit = (id) => {
  //   // alert(`Edit item with ID: ${id}`);
  // }

  // const handleDelete = (id) => {
  //   // alert(`Delete item with ID: ${id}`);
  // }

  // const handleRead = (id) => {
  //   // alert(`Read item with ID: ${id}`);
  // }



  const columns = [
    {
      name: 'Email',
      selector: row => row.email,

    },
    {
      name: 'ID',
      selector: row => row._id,

    },
    {
      name: 'College Name',
      selector: row => row.college_name,

    },
    {
      name: 'State',
      selector: row => row.state?.state,

    },
  
    {
      name: 'Phone',
      selector: row => row.phone,

    },
    {
      name: 'Est. Year',
      selector: row => row.est_year,

    },

    {
      name: 'Actions', // Add an actions column
      cell: row => (
        <div className='d-flex text-nowrap'>
         
          <button className="btn btn-sm btn-danger mx-1" onClick={() => handleDelete(row._id)}><FaTrash /></button>
        </div>
        //  <button className="btn btn-sm btn-primary mx-1" onClick={() => handleRead(row._id)}><FaEye /></button>
        // <button className="btn btn-sm btn-warning mx-1 text-white" onClick={() => handleEdit(row._id)}><FaEdit /></button>
      ),
      ignoreRowClick: true, // Prevent row click from triggering this column
      allowOverflow: true,
      button: true,
    },
  ];




  return (
    <div className=''>

      <div className="dashboard-main-body">
        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mb-24">
          <h6 className="fw-semibold mb-0">All Collage</h6>
          <ul className="d-flex align-items-center gap-2">
            <li className="fw-medium">
              <Link to="/dashboard" className="d-flex align-items-center gap-1 hover-text-primary">
                <iconify-icon icon="solar:home-smile-angle-outline" className="icon text-lg"></iconify-icon>
                Dashboard
              </Link>
            </li>
            <li>-</li>
            <li className="fw-medium">All Collage</li>
          </ul>
        </div>

        <div className="card h-100 p-0 radius-12">
          <div className="card-header border-bottom bg-base py-16 px-24 d-flex align-items-center flex-wrap gap-3 justify-content-between">
            <div className="d-flex align-items-center flex-wrap gap-3">

            </div>
            {/* Search Input */}
            <input
              type="text"
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)} // Update search state
              style={{ marginBottom: '10px', padding: '5px', width: '300px' }}
            />
            <Link to="/add-collage" className="btn btn-primary text-sm btn-sm px-12 py-12 radius-8 d-flex align-items-center gap-2">
              <iconify-icon icon="ic:baseline-plus" className="icon text-xl line-height-1"></iconify-icon>
              Add Collage
            </Link>



          </div>
          <div className="card-body p-24">
          
            {/* <div className="table-responsive scroll-sm">
              <table className="table bordered-table sm-table mb-0">
                <thead>
                  <tr>

                    <th scope="col">CollageName</th>
                    <th scope="col">State</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">est_year</th>
                    <th scope="col" className="text-center">ID</th>
                    <th scope="col" className="text-center">Country</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    allcollage.map((item) => {
                      return (
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <img src="/universityimg.jpeg" alt="" className="w-40-px h-40-px rounded-circle flex-shrink-0 me-12 overflow-hidden" />
                              <div className="flex-grow-1">
                                <span className="text-md mb-0 fw-normal text-secondary-light">
                                  {item?.college_name}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="text-md mb-0 fw-normal text-secondary-light">{item?.state?.state} </span>
                          </td>
                          <td>{item?.email}</td>
                          <td>{item?.phone}</td>
                          <td>{item?.est_year}</td>
                          <td>{item?._id}</td>
                          <td>{item?.country?.country}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div> */}

            {console.log(allcollage, 'dffdf')}

            <DataTable
              data={filteredCollage} // Use the filtered data
              columns={columns}
              // data={allcollage}
              pagination  // Enable pagination
              highlightOnHover  // Highlight rows on hover
              striped  // Use striped rows
              selectableRows
            // Optional: If you want row selection
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllCollage

import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import Swal from 'sweetalert2';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

function AddCollage() {
    const [college_name, setCollege_Name] = useState("");
    const [user_id, setUser_Id] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [est_year, setEst_Year] = useState("");
    const [ugc_approved, setUgc_Approved] = useState("");
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [country, setCountry] = useState([]);
    const [description, setDescription] = useState("");
    const [web_url, setWeb_Url] = useState("");
    const [course, setCourse] = useState("");
    // const [streamId, setStreamid] = useState("");
    const [image, setImage] = useState(null);
    const [countryId, setCountryId] = useState("");
    const [countryObject, setCountryObject] = useState({});
    const [stateId, setStateId] = useState("");
    const [stateObject, setStateObject] = useState({});
    const [cityId, setCityId] = useState("");
    const [AllCourse, setAllCourse] = useState([]);
    const [courseValue, setCourseValue] = useState("");
    const [courseId, setCourseId] = useState("");
    const [SingleCourse, setSingleCourse] = useState([]);
    const [streamId, setStreamId] = useState(SingleCourse?.streamId?._id ?? "");
    const [streamValue, setStreamValue] = useState(SingleCourse?.streamId?.name ?? "");
    const [categoryId, setCategoryId] = useState("");
    const [categoryValue, setCategoryValue] = useState("");
    const [FeesId, setFeesId] = useState("");
    const [FeesValue, setFeesValue] = useState(SingleCourse?.fees || '');
    const [EligibilityId, setEligibilityId] = useState("");
    const [EligibilityValue, setEligibilityValue] = useState("");
    const [galleryHide, setGalleryHide] = useState(false);
    const [faculty, setFaculty] = useState({
        name: '',
        email: '',
        phone: '',
        post: ''
    });

    const navigater = useNavigate()
    



    // console.log(SingleCourse?.fees)


    const [cityObject, setCityObject] = useState({});


    // console.log(country)


    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    // const validateForm = () => {
    //     if (
    //         !user_id || !college_name || !email || !phone || !est_year ||
    //         !ugc_approved || !state || !city || !country || !description ||
    //         !web_url || !course || !streamId || !image
    //     ) {
    //         alert("Enter full details.");
    //         return false;
    //     }
    //     return true;
    // };

    const validateForm = () => {
        if (
            !college_name || !email || !phone || !est_year ||
            !ugc_approved || !state || !city || !country || !description ||
            !web_url || !course || !image || !courseValue 
        ) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Enter full details!',

            });
            return false;
        }
        return true;
    };

    function saveChange(e) {
        e.preventDefault()

        // Validate form before submitting
        if (!validateForm()) {
            return; // Stop form submission if validation fails
        }
        // console.warn({name,email,phone,country,state,city,department,zip,address,subject});
        // Create FormData object
        let formData = new FormData();
        formData.append('user_id', '63d650c22baf491028d0f2d7');
        formData.append('college_name', college_name);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('est_year', est_year);
        formData.append('ugc_approved', ugc_approved);
      
        formData.append('faculty', JSON.stringify(faculty));

        formData.append("country", JSON.stringify({
            country: countryObject,
            cityId: countryId
        }));
        formData.append("state", JSON.stringify({
            state: stateObject,
            stateId: stateId
        }));
        formData.append("city", JSON.stringify({
            city: cityObject,
            cityId: cityId
        }));
        formData.append('web_url', web_url);
        formData.append("course", JSON.stringify(course));
        // formData.append('address', address);
        formData.append('streamId', '640c71b124f80223e08f033d');
        formData.append('description', description);
        formData.append('college_pics', image);

        console.log(formData);

        fetch(baseUrl + "add-college", {
            method: 'Post',
            headers: {
                'Accept': 'application/json',

            },
            body: formData
        }).then((result) => {
            if (result.status == 200) {
                navigater("/all-collage")
            }
            console.log(result.status)
        })
   
    }


   





    const handleCoursesSubmit = (e) => {
        setGalleryHide(true)
        e.preventDefault();
        let pushCF = {
            course: courseValue,
            courseId: courseId,
            fees: FeesValue,
            eligibility: EligibilityValue,
            stream: SingleCourse?.streamId?.name,
            streamId: SingleCourse?.streamId?._id,
            category: SingleCourse?.categoryId?.name,
            categoryId: SingleCourse?.categoryId?._id,
            parent: SingleCourse?.categoryId?.parent,
        };

        setCourse([...course, pushCF]);
    }


    function get_Country() {

        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };

        fetch(baseUrl + `get-country`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response) {
                    console.log(response.data);
                    setCountry(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function get_State() {

        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };

        fetch(baseUrl + `get-state/${countryId}`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response) {
                    console.log(response.data);
                    setState(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function get_City() {

        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };

        fetch(baseUrl + `get-city/${stateId}`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response) {
                    console.log(response.data);
                    setCity(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function get_Course() {

        const requestOptions = {
            method: "GET",
            redirect: "follow",
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        };



        fetch(baseUrl + `get-course`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response) {
                    // console.log(response.data);
                    setAllCourse(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function get_SingleCourse(id) {

        const requestOptions = {
            method: "GET",
            redirect: "follow",
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        };



        fetch(baseUrl + `get-single-course/${id}`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response) {
                    console.log(response.data);
                    setSingleCourse(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }


    useEffect(() => {
        get_Country();
        get_Course();
    }, [])
    useEffect(() => {
        get_State();
    }, [countryId]);
    useEffect(() => {
        get_City();
    }, [stateId]);
    useEffect(() => {
        get_SingleCourse(courseId);
    }, [courseId]);
    useEffect(() => {
        if (SingleCourse?.fees !== undefined) {
            setFeesValue(SingleCourse.fees);
        }
    }, [SingleCourse?.fees]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log(name)
        setFaculty({
            ...faculty,
            [name]: value,  // Dynamically update the form state based on input name
        });
        console.log(faculty);
    };
   
    function handleCountry(event) {
        const selectedValue = event.target.value;
        // console.log(selectedValue); // Log the selected country name
        const selectedIndex = event.target.selectedIndex;
        const selectedOption = event.target.options[selectedIndex]; // Use event.target.options
        const selectedOptionId = selectedOption.getAttribute("id"); // Get the 'id' attribute from the <option>
        // Set the state with the selected option's id and value
        setCountryId(selectedOptionId);
        setCountryObject(selectedValue);
    }

    function handleState(event) {
        const selectedValue = event.target.value; // Get the selected value (state name)
        const selectedIndex = event.target.selectedIndex; // Get the selected index
        const selectedOption = event.target.options[selectedIndex]; // Get the selected option

        const selectedId = selectedOption.getAttribute("id"); // Get the id of the selected option

        setStateId(selectedId); // Update stateId with the selected option's id
        setStateObject(selectedValue); // Update stateObject with the selected option's name


    }

    function handleCity(event) {
        const index = event.target.selectedIndex;
        const optionElement = event.target.childNodes[index];
        const optionElementId = optionElement.getAttribute("id");

        const index2 = event.target.selectedIndex;
        const optionElement2 = event.target.childNodes[index2];
        const optionElementVale2 = optionElement2.getAttribute("value");
        setCityId(optionElementId);

        setCityObject(optionElementVale2)
    }


    const handleCourse = (e) => {
        const index = e.target.selectedIndex;
        const optionElement = e.target.childNodes[index];
        const optionElementId = optionElement.getAttribute("id");
        setCourseValue(e.target.value);
        setCourseId(optionElementId);
    };

    function handleStream(event) {
        const index = event.target.selectedIndex;
        const optionElement = event.target.childNodes[index];
        const optionElementId = optionElement.getAttribute("id");
        const index2 = event.target.selectedIndex;
        const optionElement2 = event.target.childNodes[index2];
        const optionElementVale2 = optionElement2.getAttribute("value");

        setStreamValue(optionElementVale2);
        setStreamId(optionElementId);
    }

    function handleCategory(event) {
        const index = event.target.selectedIndex;

        // console.log(event.target.value);
        const optionElement = event.target.childNodes[index];
        const optionElementId = optionElement.getAttribute("id");
        const index2 = event.target.selectedIndex;
        const optionElement2 = event.target.childNodes[index2];
        const optionElementVale2 = optionElement2.getAttribute("value");
        setCategoryValue(optionElementVale2);
        setCategoryId(optionElementId);
    }

    function handleFees(event) {
        setFeesValue(event.target.value);
        setFeesId(event.target.id);
    }

    function handleEligibility(event) {
        setEligibilityValue(event.target.value)
    }


    return (

        <div classNameName=''>

            <div className="dashboard-main-body">
                <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mb-24">
                    <h6 className="fw-semibold mb-0">Add collage</h6>
                    <ul className="d-flex align-items-center gap-2">
                        <li className="fw-medium">
                            <Link to="/Dashboard" className="d-flex align-items-center gap-1 hover-text-primary">
                                <iconify-icon icon="solar:home-smile-angle-outline" className="icon text-lg"></iconify-icon>
                                Dashboard
                            </Link>
                        </li>
                        <li>-</li>
                        <li className="fw-medium">- Add collage</li>
                    </ul>
                </div>

                <div className="card h-100 p-0 radius-12 overflow-hidden">
                    <div className="card-body p-40">
                        <form action="#">
                            <div className="row">

                                <div className="col-sm-6">
                                    <div className="mb-20">
                                        <label htmlFor="name" className="form-label fw-semibold text-primary-light text-sm mb-8">College Name<span className="text-danger-600">*</span></label>
                                        <input type="text" className="form-control radius-8" value={college_name} onChange={(e) => { setCollege_Name(e.target.value) }} id="college_name" placeholder="Enter College Name" />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="mb-20">
                                        <label htmlFor="country" className="form-label fw-semibold text-primary-light text-sm mb-8">Email <span className="text-danger-600">*</span> </label>
                                        <input type="text" className="form-control radius-8" value={email} onChange={(e) => { setEmail(e.target.value) }} id="streamId" placeholder="Enter Email" />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="mb-20">
                                        <label htmlFor="number" className="form-label fw-semibold text-primary-light text-sm mb-8">Phone Number</label>
                                        <input type="phone" className="form-control radius-8" value={phone} onChange={(e) => { setPhone(e.target.value) }} id="phone" placeholder="Enter phone number" />
                                    </div>
                                </div>


                                <div className="col-sm-6">
                                    <div className="mb-20">
                                        <label htmlFor="state" className="form-label fw-semibold text-primary-light text-sm mb-8">Country <span className="text-danger-600">*</span> </label>
                                        <select className="form-control radius-8 form-select" value={countryObject} onChange={handleCountry} id="Country">
                                            <option value="" >Select Country</option> {/* Default option */}
                                            {country.length > 0 && // Check if country array has items
                                                country.map((value) => (
                                                    <option key={value.id} value={value.name} id={value.id}>
                                                        {value.name} {/* Access name directly if no 'data' object */}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="mb-20">
                                        <label htmlFor="state" className="form-label fw-semibold text-primary-light text-sm mb-8">State <span className="text-danger-600">*</span> </label>
                                        <select
                                            className="form-control radius-8 form-select"
                                            value={stateObject} // Make sure 'state' is the currently selected value
                                            onChange={handleState}
                                            id="state"
                                        >
                                            <option value="">Select State</option> {/* Ensure the value is empty for the default option */}
                                            {state?.length > 0 ? (
                                                state.map((item) => (
                                                    <option key={item.id} value={item.name} id={item.id}>
                                                        {item.name}
                                                    </option>
                                                ))
                                            ) : (
                                                <option disabled>Loading states...</option> // Optionally show a loading state
                                            )}
                                        </select>

                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="mb-20">
                                        <label htmlFor="state" className="form-label fw-semibold text-primary-light text-sm mb-8">City <span className="text-danger-600">*</span> </label>
                                        <select className="form-control radius-8 form-select" value={cityObject} onChange={handleCity} id="City">
                                            <option selected >Select State</option>
                                            {city?.map((value) => {
                                                return (
                                                    <option value={value.name} id={value.id} >
                                                        {value.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="mb-20">
                                        <label htmlFor="city" className="form-label fw-semibold text-primary-light text-sm mb-8">Ugc Approved  <span className="text-danger-600">*</span> </label>
                                        <select className="form-control radius-8 form-select" value={ugc_approved} onChange={(e) => { setUgc_Approved(e.target.value) }} id="ugc_approved">
                                            <option selected >Chek </option>
                                            <option>Approved</option>
                                            <option>Not Approved</option>

                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="mb-20">
                                        <label htmlFor="country" className="form-label fw-semibold text-primary-light text-sm mb-8">Est Year <span className="text-danger-600">*</span> </label>
                                        <input type="text" className="form-control radius-8" value={est_year} onChange={(e) => { setEst_Year(e.target.value) }} id="est_year" placeholder="Enter Est Year" />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="mb-20">
                                        <label htmlFor="country" className="form-label fw-semibold text-primary-light text-sm mb-8">Description <span className="text-danger-600">*</span> </label>
                                        <input type="text" className="form-control radius-8" value={description} onChange={(e) => { setDescription(e.target.value) }} id="description" placeholder="Enter Description" />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="mb-20">
                                        <label htmlFor="zip" className="form-label fw-semibold text-primary-light text-sm mb-8"> Web_Url  <span className="text-danger-600">*</span></label>
                                        <input type="text" className="form-control radius-8" value={web_url} onChange={(e) => { setWeb_Url(e.target.value) }} id="web_url" placeholder="Web_Url" />
                                    </div>
                                </div>

                                <h5>Course & Fee</h5>
                                <div className="col-sm-6">
                                    <div className="mb-20">
                                        <label htmlFor="state" className="form-label fw-semibold text-primary-light text-sm mb-8">Course <span className="text-danger-600">*</span> </label>
                                        <select className="form-control radius-8 form-select" value={courseValue} onChange={handleCourse} id={courseId}
                                        >


                                            {AllCourse?.map((value) => {
                                                return (
                                                    <option value={value.name} id={value._id} >
                                                        {value.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="mb-20">
                                        <label htmlFor="state" className="form-label fw-semibold text-primary-light text-sm mb-8">Stream <span className="text-danger-600">*</span> </label>
                                        <select className="form-control radius-8 form-select" value={courseValue} onChange={handleStream} id={courseId}
                                        >
                                            <option
                                                value={SingleCourse?.streamId?.name}
                                                id={SingleCourse?.streamId?._id}
                                            >
                                                {SingleCourse?.streamId?.name}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="mb-20">
                                        <label htmlFor="state" className="form-label fw-semibold text-primary-light text-sm mb-8">Category <span className="text-danger-600">*</span> </label>
                                        <select className="form-control radius-8 form-select" value={course} onChange={handleCategory} id="course">
                                            <option
                                                value={SingleCourse?.categoryId?.name}
                                                id={SingleCourse?.categoryId?._id}
                                            >
                                                {SingleCourse?.categoryId?.name}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="mb-20">
                                        <label htmlFor="country" className="form-label fw-semibold text-primary-light text-sm mb-8">Fees <span className="text-danger-600">*</span> </label>
                                        <input type="text" className="form-control radius-8" value={FeesValue} onChange={handleFees} id="streamId" placeholder="Enter Fees Amount" />
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="mb-20">
                                        <label htmlFor="state" className="form-label fw-semibold text-primary-light text-sm mb-8">Eligibility <span className="text-danger-600">*</span> </label>
                                        <select className="form-control radius-8 form-select" value={EligibilityValue} onChange={handleEligibility} id="course">
                                            <option selected >Select Eligibility</option>
                                            <option value="10+2">10+2</option>
                                            <option value="PG">PG</option>
                                            <option value="UG">UG</option>

                                        </select>
                                    </div>
                                    <button type='submit' className='btn btn-primary' onClick={handleCoursesSubmit}>Continue</button>


                                </div>


                                {galleryHide === true ? (
                                    <>

                                        <div className="col-sm-12">
                                            <h5 className="mt-4">Gallery</h5>
                                            <div className="mb-20">
                                                <label
                                                    htmlFor="image"
                                                    className="form-label fw-semibold text-primary-light text-sm mb-8"
                                                >
                                                    Upload Image <span className="text-danger-600">*</span>
                                                </label>
                                                <input
                                                    type="file"
                                                    className="form-control radius-8"
                                                    onChange={handleImageChange}
                                                    id="image"
                                                    accept="image/*"
                                                />
                                            </div>
                                        </div>


                                        <h5 className="mt-4">Faculty</h5>

                                        


                                            <div className="col-sm-6">
                                                <div className="mb-20">
                                                    <label htmlFor="country" className="form-label fw-semibold text-primary-light text-sm mb-8"> Add Faculty Name <span className="text-danger-600">*</span> </label>
                                                    {/* <input type="text" className="form-control radius-8" value={facultyName} onChange={handleFaculty} id="Faculty" placeholder="Enter  Faculty Name" /> */}
                                                    <input type="text" name="name"  className="form-control radius-8" value={faculty.name}
                                                        onChange={handleInputChange} placeholder="Enter Faculty Name" />
                                                </div>
                                            </div>

                                            <div className="col-sm-6">
                                                <div className="mb-20">
                                                    <label htmlFor="country" className="form-label fw-semibold text-primary-light text-sm mb-8"> Add Faculty Phone <span className="text-danger-600">*</span> </label>
                                                    <input type="text" name="phone"  className="form-control radius-8" value={faculty.phone}
                                                        onChange={handleInputChange} placeholder="Enter Faculty Phone" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="mb-20">
                                                    <label htmlFor="country" className="form-label fw-semibold text-primary-light text-sm mb-8"> Add Faculty Email <span className="text-danger-600">*</span> </label>
                                                    <input type="email" name="email"  className="form-control radius-8" value={faculty.email}
                                                        onChange={handleInputChange} placeholder="Enter Faculty Email" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="mb-20">
                                                    <label htmlFor="country" className="form-label fw-semibold text-primary-light text-sm mb-8"> Add Faculty Post <span className="text-danger-600">*</span> </label>
                                                    <input type="text" name="post"  className="form-control radius-8" value={faculty.post}
                                                        onChange={handleInputChange} placeholder="Enter Faculty Post" />
                                                </div>
                                            </div>

                                          
                                        





                                        <div className="d-flex align-items-center justify-content-center gap-3 mt-24">
                                            <button
                                                type="submit"
                                                className="btn btn-primary border border-primary-600 text-md px-24 py-12 radius-8"
                                                onClick={saveChange}
                                            >
                                                Save Change
                                            </button>
                                        </div>
                                    </>
                                ) : null}

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default AddCollage

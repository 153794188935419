import React, { useEffect } from 'react';
import $ from 'jquery';
import logo from '../style/assets/images/edudronimg.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import AddCollageIcon from '../../src/images/college-school-svgrepo-com.svg';
import AddCollageIcon1 from '../../src/images/school.png';

import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faAppStoreIos } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router-dom';
import { width } from '@fortawesome/free-brands-svg-icons/fa42Group';
// import { BsBank } from "react-icons/bs";


const Sidebar = ({ isSidebarOpen }) => {
  const navigate = useNavigate();
  useEffect(() => {
    // Ensure that only the clicked nav-item's parent li gets the 'open' class
    $('.nav-item').on('click', function (e) {
      // e.preventDefault(); // Prevent default anchor behavior
      $(this).closest('.dropdown').toggleClass('open');
    });
    
    // Cleanup to remove event listeners when the component unmounts
    return () => {
      $('.nav-item').off('click');
    };
  }, []);

  return (
    <aside className={`sidebar ${isSidebarOpen ? 'active' : ''}`}>
      <button type="button" className="sidebar-close-btn">
        {/* Close button content can be added here */}
      </button>
      <div>
        <Link to="/" className="sidebar-logo">
          <img src={logo} alt="site logo" className="light-logo m-auto" />
          <img src={logo} alt="site logo" className="dark-logo m-auto" />
          <img src={logo} alt="site logo" className="logo-icon" />
        </Link>
      </div>
      <div className="sidebar-menu-area">
        <ul className="sidebar-menu" id="sidebar-menu">
          <li className='mb-2'>
            <Link to="/dashboard" className="nav-item">
              <FontAwesomeIcon icon={faHome} />
              <span className="nav-text" >Dashboard</span>
            </Link>
          </li>
          <li  className='mb-2'>
            <Link to="/all-collage" className="nav-item">
            <img src={AddCollageIcon} className='addicon' alt="Logo" />
              <span className="nav-text" >All college</span>
            </Link>
          </li>
          <li className='mb-2'>
            <Link to="/add-collage" className="nav-item">
            <img src={AddCollageIcon1} className='addicon1' alt="Logo" />
              <span className="nav-text" >Add College</span>
            </Link>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;


         //   <li className='mb-2'>
         {/* <Link to="/user" className="nav-item"> */}
         {/* <FontAwesomeIcon icon={faHome} /> */}
         {/* <FontAwesomeIcon icon="fa-solid fa-graduation-cap" /> */}
         {/* <span className="nav-text" >All User</span> */}
         // </Link>
         // </li>